import { useState } from "react";
import { Wheel } from 'react-custom-roulette'
import ConfettiExplosion from 'react-confetti-explosion';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getFunctions, httpsCallable } from 'firebase/functions';
import toast from 'react-simple-toasts';

import Ticker from "./assets/ticker.png";

// Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDRxSKvPawInKFTO7hsArTn6E6bSJhtHw4",
  authDomain: "bennyaucoq.firebaseapp.com",
  projectId: "bennyaucoq",
  storageBucket: "bennyaucoq.appspot.com",
  messagingSenderId: "349806252507",
  appId: "1:349806252507:web:d3d5f9e87145e6a65457fe"
};

const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lf_y9slAAAAAC3kU_iCwZ7lSroaqPhwu27yewMq'),
  isTokenAutoRefreshEnabled: true
});

const functions = getFunctions(app);

// Component
function App() {
  const [step, setStep] = useState(0);
  const [mustSpin, setMustSpin] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [email, setEmail] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [prize, setPrize] = useState(undefined);
  const [winningIndex, setWinningIndex] = useState(undefined)

  const data = [
    { option: 'FRITES', style: { backgroundColor: '#E21A23', textColor: '#fff', fontSize: '28' } },
    { option: 'RED5', style: { backgroundColor: '#fcb316', textColor: '#013a80', fontSize: '28' } },
    { option: 'RED2', style: { backgroundColor: '#013a80', textColor: '#fff', fontSize: '28' } },
    { option: 'POUT', style: { backgroundColor: '#FEE000', textColor: '#013a80', fontSize: '28' } },
  ]

  const handleSignup = (e) => {
    e.preventDefault()
    if(!email) {
      toast('Un courriel valide doit être utilisé', {
        duration: 3000,
        className: "error-toast"
      });
      return
    }
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(email.match(emailFormat)){
      setLoading(true)
      const functions = getFunctions()
      const payload = httpsCallable(functions, 'checkEmail');
      payload({ email: email }).then((result) => {
        if(result.data.status === "email not used") {
          setLoading(false)
          setStep(1)
        } else {
          setLoading(false)
          toast('Ce courriel a déjà participé à la promotion', {
            duration: 3000,
            className: "error-toast"
          });
        }
      })
    } else {
      toast('Un courriel valide doit être utilisé', {
        duration: 3000,
        className: "error-toast"
      });
    }
  }

  const handleSpin = () => {
    setLoading(true)
    const functions = getFunctions()
    const payload = httpsCallable(functions, 'pickPrize');
    payload({ email: email }).then((result) => {
      setLoading(false)
      setWinningIndex(result.data.randomNumber)
      setPrize(result.data.prize)
      setMustSpin(true)
      setAnimation(true)
    })
  }

  const getProperWinningText = () => {
    switch (prize) {
      case "FRITES":
          return "des frites gratuites avec votre commande"
      case "RED5":
          return "une réduction de $5.00 sur votre commande de $25.00 et plus"
      case "RED2":
          return "une réduction de $2.00 sur votre commande de $20.00 et plus"
      case "POUT":
          return "un remplacement de frites à poutine pour seulement $1.00"
      default:
          break;
  }
  }

  return (
    <div className="App">
      <div className="background" />
      {step === 0 &&
        <div className="container">
          <h3 className="title">Jouez pour gagner!</h3>
          <p className="description">Tournez la roue pour gagner des prix incroyables tels que des frites gratuites et des rabais sur votre commande en ligne. Vous avez une chance de gagner gros à chaque tour.</p>
          <form onSubmit={(e) => handleSignup(e)}>
            <input placeholder="Votre courriel" type="text" onChange={(e) => setEmail(e.target.value)}></input>
            <button type="submit" disabled={loading}>{loading ? "Validation du courriel..." : "Jouez maintenant"}</button>
          </form>
          <p className="disclaimer">Utilisable avec votre commande en ligne sur notre site web.</p>
        </div>
      }
      {(step === 1 || step === 2) &&
        <div className="wheel__container">
          <h3 className="title">{step === 1 ? "Tentez votre chance!" : "Félicitations!"}</h3>
          <div className={animation ? "animation" : ""}>
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={winningIndex}
              data={data}
              backgroundColors={['#3e3e3e', '#df3428']}
              textColors={['#1c1c1c']}
              pointerProps={{ src: Ticker }}
              perpendicularText={true}
              radiusLineWidth={10}
              radiusLineColor="#F1ECE0"
              innerBorderColor="#F1ECE0"
              outerBorderColor="#F1ECE0"
              startingOptionIndex={1}
              onStopSpinning={() => {setAnimation(false); setStep(2); setIsExploding(true)}}
            />
          </div>
          {isExploding && 
              <ConfettiExplosion 
                particleCount={250}
                duration={3500}
                zIndex={999}
                color={["#E21A23", '#fcb316', '#013a80', '#FEE000', "#c8171e", "#012e66"]}
              />
          }
          {step === 1 &&
            <>
              {loading ? <button disabled>Votre prix se fait pondre...🐔</button> :<button disabled={mustSpin} onClick={() => handleSpin()}>{mustSpin ? "Votre prix se fait pondre...🐔" : "Tournez et gagnez!"}</button>}
              <p className="disclaimer"><b>POUT:</b> Changez votre frite pour une poutine pour $1.00 - <b>FRITES:</b> Obtenez une frite gratuite avec votre commande<br/><b>RED2:</b> Réduction de $2.00 sur une commande de $20.00 et plus - <b>RED5:</b> Réduction de $5.00 sur une commande de $25.00 et plus </p>
            </>
          }
          {step === 2 &&
            <>
              <p className="winner">
                <b>Félicitations!</b> Vous avez gagné <b>{getProperWinningText()}!</b>
                <br />
                <u>Vous recevrez votre code promo par courriel dans les prochaines minutes.</u>
              </p>
              <a href="https://rotisseriebenny.order-online.ai/fr/#/" target="_blank"><button>Utiliser mon code promo</button></a>
            </>
          }
        </div>
      }
    </div>
  );
}

export default App;
